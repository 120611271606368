const activeEnv = require('./activeEnv')
const globalConfig = require('../../../config')

const { BRANCH_HASH } = process.env

module.exports = {
  apiUrl: (() => {
    if (activeEnv === 'development') return 'http://localhost:8037/dev'
    if (BRANCH_HASH) return `https://serverless.myanmargold.org/${BRANCH_HASH}`
    if (activeEnv === 'production')
      return 'https://serverless.myanmargold.org/production'
    return ''
  })(),
  stripeApiKey: (
    globalConfig[process.env.STRIPE_ENV || activeEnv] || 'development'
  ).stripePublicApiKey,
}
