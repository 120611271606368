module.exports = {
  development: {
    stripePublicApiKey: 'pk_test_pGVASB16x6Gp4P2NYjYmd2ZB0052QCwxQq',
    stripeSecretApiKey: 'sk_test_3H98LVqzSJxqw4ij7Rt7f8rY00Rg8nVZIT',
  },
  staging: {
    stripePublicApiKey: 'pk_test_pGVASB16x6Gp4P2NYjYmd2ZB0052QCwxQq',
    stripeSecretApiKey: 'sk_test_3H98LVqzSJxqw4ij7Rt7f8rY00Rg8nVZIT',
  },
  production: {
    stripePublicApiKey: 'pk_live_TrYGNFvhEnn47axorhMmTYGl00WtV5Sala',
    stripeSecretApiKey: 'sk_live_92p5VAPIblmNA1CpEp9NSIVQ00JFID3fHw',
  },
}
