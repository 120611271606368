import React from 'react'
import { CheckoutForm } from '@foundations-of-grace-pub/checkout'
import SEO from '../components/Seo'
import BaseLayout from '../components/layouts/BaseLayout'
import config from '../../config'
import Container from '../components/layouts/Container'

export default () => (
  <BaseLayout>
    <SEO title="Purchase" />
    <Container>
      <CheckoutForm stripeApiKey={config.stripeApiKey} />
    </Container>
  </BaseLayout>
)
